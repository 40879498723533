import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const allFrameCount = 89;
const frameInitCount = 30;
const frameScrolledCount = 59;

interface IProps {
  canvasRef: React.RefObject<HTMLCanvasElement>
}

export const useLogoImages = ({ canvasRef }: IProps) => {
  const [loaded, setLoaded] = useState(false);
  const [logoImages, setLogoImages] = useState<HTMLImageElement[]>([]);
  const [logoImagesInit, setLogoImagesInit] = useState<HTMLImageElement[]>([]);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  useEffect(() => {
    const canvasEl = canvasRef?.current;
    if (!canvasEl) {
      return;
    }

    canvasEl.width = 1920;
    canvasEl.height = 640;

    setContext(canvasEl?.getContext('2d'));
  }, [canvasRef]);

  useMemo(() => {
    if (!context) {
      return;
    }

    const currentFrame = (index: number) => (
      `/images/13button8/13button8_${index.toString().padStart(3, '0')}-min.png`
    );

    const imagesInit: HTMLImageElement[] = [];
    const images: HTMLImageElement[] = [];

    for (let i = 0; i < allFrameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
      if (i < frameInitCount) {
        imagesInit.push(img);
      } else {
        images.push(img);
      }
    }

    images[images.length - 1].onload = () => {
      setLogoImages(images);
      setLogoImagesInit(imagesInit);
      setLoaded(true);
    };
  }, [context]);

  const renderLogo = useCallback((frameIndex: number) => {
    const canvasEl = canvasRef?.current;

    if (!context || !canvasEl) {
      return;
    }

    context.clearRect(0, 0, canvasEl.width, canvasEl.height);
    context.drawImage(logoImages[frameIndex], 0, 0);
  }, [logoImages, canvasRef, context]);

  const renderLogoInit = useCallback((frameIndex: number) => {
    const canvasEl = canvasRef?.current;

    if (!context || !canvasEl) {
      return;
    }

    context.clearRect(0, 0, canvasEl.width, canvasEl.height);
    context.drawImage(logoImagesInit[frameIndex], 0, 0);
  }, [logoImagesInit, canvasRef, context]);

  return {
    canvasRef,
    loaded,
    frameInitCount,
    frameScrolledCount,
    renderLogo,
    renderLogoInit
  };
};
