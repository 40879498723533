export enum MediaTypes {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

export enum Breakpoints {
  Mobile = 320,
  Tablet = 600,
  Desktop = 1024
}

export enum BreakpointsExt {
  Mobile = 375
}

export type TMedia = (
  'mobile' |
  'tablet' |
  'desktop'
);
