import { ITweens } from 'src/pages/Page/types';
import { gsap } from 'gsap';
import { MediaTypes } from 'src/shared/types/layout';
import { THTMLElement } from 'src/shared/types/common';

interface IProps {
  elem: THTMLElement
  label: string
  media: MediaTypes
  tweens?: ITweens
  timeline: gsap.core.Timeline
}
export const startVideoSceneTween = ({ elem, label, media, tweens, timeline: tl }: IProps) => {
  const q = gsap.utils.selector(elem);

  const isMobile = media === MediaTypes.Mobile;

  const videoIframe = q('.video-iframe');

  if (!tweens) {
    return tl;
  }

  if (isMobile) {
    return tl
      // .add(tweens.startVideoScene.startVideoSceneInMobile(label))
      // .add(tweens.videoIframe.collapseBorders(videoIframe, label))
      .add(tweens.headerWrapper.reduceHeight(label))
      .add(tweens.overlay.hideOverlay(label))
      .add(tweens.logo.hideLogoMobile());
  }
  return tl
    // .add(tweens.startVideoScene.startVideoSceneIn(label))
    // .add(tweens.videoIframe.collapseBorders(videoIframe, label))
    .add(tweens.headerWrapper.reduceHeight(label))
    .add(tweens.overlay.hideOverlay(label))
    .add(tweens.logo.schrinkLogo(label));
};
