import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, md, sd } = scales;

interface IPreviewProps {
  canPlayVideo: boolean;
}

export const NewFormatVideoWrapper = styled.span`
  position: absolute;
  border-radius: 10px;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  pointer-events: none;
  opacity: 0;

  ${mq({
    marginLeft: [sd(5), null, md(5), lg(10)],
    width: [sd(84), null, md(84), lg(172)],
    height: [sd(40), null, md(40), lg(80)]
  })}
`;

export const NewFormatVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  user-select: none;
`;

export const NewFormatIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;

export const NewFormatImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  user-select: none;
`;

export const NewFormatText = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-weight: 500;

  ${mq({
    paddingLeft: [sd(16), null, md(16), lg(60)],
    paddingRight: [sd(16), null, md(16), lg(60)]
  })}
`;

export const NewFormatTopText = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  opacity: 0;
  /* top: 50px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  top: 5vw;

  ${mq({
    paddingBottom: [sd(16), null, md(24), lg(24)]
  })}

  ${mq({
    fontSize: [sd(14), null, md(14), lg(24)]
  })};
`;
export const NewFormatBottomText = styled.div`
  opacity: 0;
  position: relative;
  font-weight: bold;
  white-space: pre-line;

  ${mq({
    bottom: [sd(-50), null, md(-100), lg(-100)],
    fontSize: [sd(16), null, md(16), lg(32)],
    lineHeight: [sd(22), null, md(22), lg(48)]
  })}
`;

export const Preview = styled.img<IPreviewProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  opacity: ${(props) => (props.canPlayVideo ? 0 : 1)};
  transition-duration: 2s;
  z-index: 1;
`;
