import { useEffect, useRef, useState } from 'react';
import { LimitedText } from 'src/shared/hocs/LimitedText';
import * as Styled from './newFormatVideo.styled';

interface IProps {
  id?: string
  title: string
  subtitle: string
  description: string
  link: string
  previewLink: string
}
export const NewFormatVideo = ({ id, title, subtitle, description, link, previewLink }: IProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [canPlayVideo, setCanPlayVideo] = useState(false);

  useEffect(() => {
    const onLoadedData = () => {
      setCanPlayVideo(true);
    };

    videoRef?.current?.addEventListener('loadeddata', onLoadedData);

    return () => {
      videoRef?.current?.removeEventListener('loadeddata', onLoadedData);
    };
  }, []);

  return <Styled.NewFormatVideoWrapper id={id} className='new-format-video-wrapper'>
    <Styled.Preview src={previewLink} canPlayVideo={canPlayVideo} />
    <Styled.NewFormatVideo
      ref={videoRef}
      className='new-format-video'
      muted={true}
      playsInline={true}
      preload='auto'
      controls={false}
      autoPlay={true}
      loop={true}
      width='100%'
      height='100%'
    >
      <source src={link} type='video/mp4' />
    </Styled.NewFormatVideo>
    <Styled.NewFormatText>
      <Styled.NewFormatTopText className='new-format-top-text'>
        <LimitedText text={title} lines={2} />
        <LimitedText text={subtitle} lines={2} />
      </Styled.NewFormatTopText>
      <Styled.NewFormatBottomText className='new-format-bottom-text'>
        <LimitedText text={description} lines={4} />
      </Styled.NewFormatBottomText>
    </Styled.NewFormatText>

  </Styled.NewFormatVideoWrapper>;
};
