import { commonDuration } from 'src/pages/constants';
import { IOverlayTweensTweensMethods, ITweenProps } from 'src/shared/tweens/types';

export const overlayTweens = (tweenProps: ITweenProps): IOverlayTweensTweensMethods => {
  const { selectors: { overlay }, tl } = tweenProps;

  const hideOverlayMobile = () => {
    return tl.to(overlay, { autoAlpha: 0 });
  };

  const hideOverlay = (label: string) => {
    return tl.to(overlay, { duration: commonDuration, opacity: 0, delay: 0.5 }, label);
  };

  return {
    hideOverlayMobile,
    hideOverlay
  };
};
