import { IRepeatedContentVideo } from 'shared/types/common';
import { mainText } from 'src/pages/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getMq } from 'src/pages/Page/helpers/getMq';

import { truncateText } from 'shared/helpers/truncateText';
import { NewFormatVideo } from '../NewFormatVideo';
import * as Styled from './textPlot.styled';

interface IProps {
  video: IRepeatedContentVideo
}
export const TextPlot = ({ video }: IProps) => {
  const trancatedMainText = truncateText(mainText, 30);
  const media = useSelector((state: RootState) => state.layout.media);
  const mq = getMq(media);

  const {
    videoDesktop, videoTablet, videoMobile, title, subtitle, description,
    picDesktop, picMobile, picTablet
  } = video;

  return <Styled.TextPlot id='text-scene'>
    <Styled.TextWrapper id='text-wrapper'>
      <Styled.Text id='text'>{trancatedMainText}</Styled.Text>
      <NewFormatVideo
        previewLink={mq([picMobile, picTablet, picDesktop])}
        id='new-format-video-wrapper-main'
        link={mq([videoMobile, videoTablet, videoDesktop])}
        title={title}
        subtitle={subtitle}
        description={description}
      />
    </Styled.TextWrapper>
  </Styled.TextPlot>;
};
