export interface ISlide {
  url: string
  playerLink?: string
  title?: string
  videoNumber?: number
  videoNumberInSeason?: number
};

export interface IVideo {
  pak_id: number
  preview: string
  title: string
  player_url: string
  video_number: number
  video_number_in_season: number
}

export interface IData {
  pic_desktop: string
  pic_mobile: string
  pic_tablet: string
  project_title: string
  project_description: string
  subtitle: string
  title: string
  video_desktop: string
  video_mobile: string
  video_tablet: string
  videos: IVideo[]
}

export enum EDataTypes {
  Teasers = 'teasers',
  AboutProject = 'about_project'
}

export interface IDataItem {
  type: EDataTypes
  data: IData
}

export interface IResponse {
  data: IDataItem[]
  status: string
}

export interface IRepeatedContentVideo {
  videoDesktop: string
  videoMobile: string
  videoTablet: string
  picDesktop: string
  picMobile: string
  picTablet: string
  title: string
  subtitle: string
  description: string
}

export interface IRepeatedContentDescription {
  picDesktop: string
  picMobile: string
  picTablet: string
  title: string
  description: string
}

export interface IRepeatedContent {
  video?: IRepeatedContentVideo
  slider?: ISlide[]
  description?: IRepeatedContentDescription
}

export type THTMLElement = HTMLElement[] | null;
