/* eslint-disable max-lines */
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLogoImages } from 'shared/hooks/useLogoImages';
import { Loader } from 'src/pages/Page/components/Loader';
import { Header } from 'src/pages/Page/components/Header';
import { StartVideoScene } from 'src/pages/Page/components/StartVideoScene';
import { useGetContent } from 'src/shared/hooks/useGetContent';
import { realToVirtualContentAdapter } from 'src/pages/Page/helpers/realToVirtualContentAdapter';
import { APP_API } from 'src/shared/config/config';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { TextPlot } from 'src/pages/Page/components/TextPlot';
import { ProjectVideoPlot } from 'src/pages/Page/components/ProjectVideoPlot';
import { ProjectCardPlot } from 'src/pages/Page/components/ProjectCardPlot';
import { SliderPlot } from 'src/pages/Page/components/SliderPlot';
import { PageContext } from './context';
import { useStartPlot } from './hooks/useStartPlot';
import * as Styled from './page.styled';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

ScrollTrigger.normalizeScroll(true);

const apiUrl = `${APP_API}api/widgets`;

export const Page = () => {
  const media = useSelector((state: RootState) => state.layout.media);

  const main = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [smoother, setSmoother] = useState<ScrollSmoother>();

  const { data, loading, error } = useGetContent(apiUrl);

  const repeatedContent = realToVirtualContentAdapter(data || []);

  const {
    loaded: logoImageLoaded,
    frameInitCount,
    frameScrolledCount,
    renderLogo,
    renderLogoInit
  } = useLogoImages({ canvasRef });

  const loaded = logoImageLoaded && !loading && !error;

  useMemo(() => {
    if (!loaded) {
      document.body.classList.add('no-scroll');
    }
  }, [loaded]);

  useLayoutEffect(() => {
    const smoother = ScrollSmoother.create({
      smooth: 3,
      effects: true,
      normalizeScroll: true,
      ignoreMobileResize: true,
      smoothTouch: 0.1
    });
    setSmoother(smoother);
  }, []);

  useLayoutEffect(() => {
    if (!smoother) {
      return;
    }

    return () => {
      smoother.kill();
    };
  }, [smoother]);

  useStartPlot({
    main,
    smoother,
    loaded,
    media,
    repeatedContent,
    frameInitCount,
    frameScrolledCount,
    renderLogo,
    renderLogoInit
  });

  return (
    <PageContext.Provider value={{ smoother }}>
      <div className='section' ref={main} id='smooth-wrapper'>
        <div id='smooth-content'>
          <Styled.Page>
            <Styled.Trigger className='trigger'>
              <Styled.Header id='headerWrapper'><Header /></Styled.Header>
              <Styled.Loader loaded={loaded}>
                <Loader />
              </Styled.Loader>
              <Styled.Orange id='orange'>
                <Styled.MainLogo id='main-logo'>
                  <canvas ref={canvasRef} />
                </Styled.MainLogo>
                {/* <StartVideoScene id='start-video-scene' /> */}
                {repeatedContent.map(({ video, slider, description }, index) => {
                  return <React.Fragment key={index}>
                    {video && (index === 0 ? <TextPlot video={video} /> : <ProjectVideoPlot video={video} index={index} />)}
                    {slider && <SliderPlot slider={slider} index={index} />}
                    {description && <ProjectCardPlot description={description} index={index} />}
                  </React.Fragment>;
                })}
                <Styled.Footer id='footer'>
                  <Styled.FooterContent id='footer-content'>
                    © 2023 Телеканал СТС, АО «СТС»
                  </Styled.FooterContent>
                </Styled.Footer>
              </Styled.Orange>

              <Styled.Overlay className='overlay' />

            </Styled.Trigger>

          </Styled.Page>
        </div></div></PageContext.Provider>
  );
};
