import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, md, sd } = scales;

export const Page = styled.div`
  & canvas {
    user-select: none;
    touch-action: none;
    ${mq({
      width: [sd(320), null, lg(1920), null],
      height: [sd(106), null, lg(640), null]
    })}
  }
`;

export const MainLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq({
    zIndex: [7, null, 10, 10]
  })}
  position: absolute;
  top: 0;
`;

export const Trigger = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const TriggerSecond = styled.div`
  background: orange;
`;

export const Second = styled.div``;

export const Header = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  z-index: 11;
  background-color: #000;

  ${mq({
    paddingLeft: [sd(16), null, md(30), lg(60)],
    paddingRight: [sd(16), null, md(30), lg(60)]
  })}
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1;
  top: 0;
  opacity: 1;
`;

const size = '150vmax';
const duration = '40s';
const palette = '#FFE3D6, #FFE3D6,#FED570, #FF6349, #DB4532, #DB4532';

export const Orange = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-areas: 'body';
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    grid-area: body;
    width: ${size};
    height: ${size};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &::before {
    animation-name: rotate;
    animation-duration: ${duration};
    background: linear-gradient(to left, ${palette});
  }

  &::after {
    animation-name: rotate, fade;
    animation-duration: calc(${duration} / 2), ${duration};
    background: linear-gradient(to right, ${palette});
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes fade {
    50% {
      opacity: 0;
    }
  }
`;

interface ILoaderProps {
  loaded: boolean;
}
export const Loader = styled.div<ILoaderProps>`
  width: 100%;
  height: 100%;
  z-index: 5;
  background: #000;
  position: absolute;
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  & .loader-circle-8 {
    margin-top: -140px;
  }
  ${mq({
    top: 0
  })}
`;

export const VideoIframe = styled.video`
  position: absolute;
  z-index: 7;
  box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 20%);
  opacity: 0;
  object-fit: cover;
`;

export const Break = styled.br`
  ${mq({
    display: ['block', null, 'none', 'none']
  })}
`;

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  visibility: hidden;
  z-index: 5;
  width: 100%;
  height: 100vh;
  height: 100svh;
`;

export const FooterContent = styled.div`
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  width: 100%;

  ${mq({
    fontWeight: [400, null, 500, 500],
    fontSize: [sd(14), null, md(24), lg(24)],
    height: [sd(220), null, md(286), lg(286)]
  })}
`;
