
import { ITweens } from 'src/pages/Page/types';
import { THTMLElement } from 'src/shared/types/common';

interface IProps {
  elem: THTMLElement
  tweens?: ITweens
  timeline: gsap.core.Timeline
}
export const newFormatVideoTween = ({ elem, tweens, timeline: tl }: IProps) => {
  if (!tweens) {
    return tl;
  }

  return tl
    .add('newFormatVideoAppearance')
    .add(tweens.newFormatVideo.videoIn(elem, 'newFormatVideoAppearance'))
    .add(tweens.newFormatVideo.collapseBorders(elem, 'newFormatVideoAppearance'))
    .add('newFormatTextAppearance')
    .add(tweens.newFormatVideo.videoTextShow(elem, 'newFormatTextAppearance'));
};
