import * as Styled from './loader.styled';

export const Loader = () => {
  const headerHeight = document.querySelector('#headerWrapper')?.clientHeight || 0;
  return <Styled.Loader id='loader' style={{ height: window.innerHeight - headerHeight }}>
    <Styled.CenterBody>
      <Styled.LoaderCircle>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10'/>
        </svg>
        <Styled.LoaderText>13</Styled.LoaderText>
      </Styled.LoaderCircle>
    </Styled.CenterBody>
  </Styled.Loader>;
};
