import { ReactComponent as Logo } from 'shared/assets/svg/logo.svg';
import { ReactComponent as Ok } from 'shared/assets/svg/ok.svg';
import { ReactComponent as Telegram } from 'shared/assets/svg/telegram.svg';
import { ReactComponent as Vk } from 'shared/assets/svg/vk.svg';
import { Link } from 'react-router-dom';
import { links } from 'src/pages/constants';
import * as Styled from './header.styled';

const { tg, vk, ok, ctc } = links;

export const Header = () => {
  return <Styled.Header id='header' >
    <Styled.Logo>
      <Link to={ctc}><Logo /></Link>
    </Styled.Logo>
    <Styled.Socials>
      <Link to={tg} ><Telegram /></Link>
      <Link to={vk} ><Vk /></Link>
      <Link to={ok} ><Ok /></Link>
    </Styled.Socials>
  </Styled.Header>;
};
