import { ISelectorsMap } from 'src/pages/Page/types';

const selectorsMap: { [key: string]: string } = {
  triggerEl: '.trigger',
  mainLogo: '#main-logo',
  overlay: '.overlay',
  headerWrapper: '#headerWrapper',
  header: '#header',
  startVideoScene: '#start-video-scene',
  textScene: '#text-scene',
  // logoMiddle: '#logo-middle',
  // slider: '#slider',
  // marquee: '#marquee svg',
  projectCard: '#project-card',
  projectCardTitle: '#project-card-title',
  projectCardDescription: '#project-card-description',
  projectCardImage: '#project-image img',
  newFormatVideoWrapperMain: '#new-format-video-wrapper-main',
  newFormatVideoWrapper2: '#new-format-video-wrapper-2',
  projectVideoScene: '#project-video-scene-1',
  sliderScene: '#slider-scene',
  sliderScene2: '#slider-scene-2',
  text: '#text',
  textWrapper: '#text-wrapper',
  footer: '#footer',
  loader: '#loader'

};

export const getSelectors = (self: gsap.Context) => {
  if (!self.selector) {
    return;
  }

  const _selectors: ISelectorsMap = {};

  Object.keys(selectorsMap).forEach((v) => {
    if (!self.selector) {
      return;
    }
    _selectors[v] = self.selector(selectorsMap[v]);
  });

  const addSelector = (name: string, queryPath: string) => {
    if (!self.selector) {
      return;
    }
    _selectors[name] = self.selector(queryPath);
  };

  const getSelector = (name: string) => {
    return _selectors[name];
  };

  return { selectors: _selectors, addSelector, getSelector };
};
