import { ISlide } from 'src/shared/types/common';
import { SliderScene } from 'src/pages/Page/components/SliderScene';

interface IProps {
  slider: ISlide[]
  index: number
}

export const SliderPlot = ({ slider, index }: IProps) => {
  return <SliderScene id={`slider-scene-${index}`} slides={slider} />;
};
