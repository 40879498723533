import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, md, sd } = scales;

export const LogoMiddle = styled.div`
  width: 100%;

  ${mq({
    marginTop: [sd(20), null, md(200), lg(200)]
  })}

  &.no-slider {
    margin-top: 0;
  }

  @media ((min-width: 450px) and (max-width: 599px)) {
    margin-top: 0;
  }

  @media ((min-width: 500px) and (max-width: 599px)) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

interface ISliderScene {
  paddingTop: string | number;
}
export const SliderScene = styled.div<ISliderScene>`
  z-index: 6;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  padding-top: ${(props) => props.paddingTop};
`;

export const Slider = styled.div`
  width: 100vw;
  position: relative;

  ${mq({
    top: [null, null, md(30), lg(30)]
  })}

  img {
    width: 100%;
    height: 100%;
    ${mq({
      borderRadius: [sd(10), null, md(20), lg(20)]
    })}
  }
`;

export const Marquee = styled.div`
  position: relative;
  display: flex;
  svg {
    ${mq({
      marginRight: [null, null, null, lg(50)],
      width: [sd(380), null, md(814), lg(814)],
      height: [sd(71), null, md(109), lg(109)]
    })}
  }
`;
