import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { MediaTypes } from 'src/shared/types/layout';

interface IProps {
  medias: MediaTypes[]
  children: JSX.Element | JSX.Element[]
}
export const Media = ({ medias, children }: IProps) => {
  const media = useSelector((state: RootState) => state.layout.media);

  return (
    <>
      {medias.includes(media) && (
        children
      )}
    </>
  );
};
