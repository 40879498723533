import { styled } from '@linaria/react';
import { mq, scales } from 'src/shared/styles/styles.config';
import { NewFormatVideoWrapper } from '../NewFormatVideo/newFormatVideo.styled';

const { sm, sd, md, lg } = scales;

export const ProjectVideoPlot = styled.div`
  position: absolute;
  z-index: 5;
  opacity: 0;
  width: 100%;

  ${NewFormatVideoWrapper} {
    width: 100%;
    height: 100%;
    margin-left: 0;
    opacity: 1;
  }
`;
