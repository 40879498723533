import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { IRepeatedContent, THTMLElement } from 'src/shared/types/common';
import { getSelectors } from 'src/pages/Page/helpers/getSelectors';
import { Scenes } from 'src/pages/Page/scenes';
import { Tweens } from 'src/pages/Page/tweens';
import { debounce } from 'src/shared/helpers/debounce';
import { commonDuration } from 'src/pages/constants';
import { ICloseItem } from 'src/pages/Page/types';
import { MediaTypes } from 'src/shared/types/layout';

interface IProps {
  main: React.RefObject<HTMLDivElement>
  smoother?: ScrollSmoother
  loaded: boolean
  media: MediaTypes
  repeatedContent: IRepeatedContent[]
  frameInitCount: number
  frameScrolledCount: number
  renderLogo: (frameIndex: number) => void
  renderLogoInit: (frameIndex: number) => void
}

export const useStartPlot = (props: IProps) => {
  const {
    main, smoother, loaded, media, repeatedContent, frameInitCount,
    frameScrolledCount, renderLogo, renderLogoInit
  } = props;

  const inited = useRef(false);
  const timeline = useRef<gsap.core.Timeline>();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    if (!loaded) {
      return;
    }

    const initFrames = {
      frame: 0
    };

    const scrolledFrames = {
      frame: 0
    };

    const ctx = gsap.context(self => {
      if (!self.selector) {
        return;
      }

      const { selectors } = getSelectors(self) || {};

      if (!selectors) {
        return;
      }

      const screenHeight = window.innerHeight - selectors.header[0].clientHeight;

      timeline.current = gsap.timeline({
        scrollTrigger: {
          trigger: selectors.triggerEl,
          start: 'clamp(top top)',
          end: '+=6000',
          scrub: true,
          pin: true,
          fastScrollEnd: true,
          pinSpacing: true
        }
      });

      const tl = timeline.current;

      const render = () => {
        renderLogo(scrolledFrames.frame);
      };

      const renderInit = () => {
        renderLogoInit(initFrames.frame);
      };

      const isMobile = media === MediaTypes.Mobile;

      const tweens = Tweens(self, tl, media);
      const scenes = Scenes(self, tl, media);

      if (!tweens) {
        return;
      }

      const getSelector = (path: string) => {
        if (!self.selector) {
          return;
        }
        return self.selector(path);
      };

      tweens.logo.init();
      tweens.startVideoScene.init(screenHeight);

      const textSceneOut = (label: string) => {
        return tl.add(tweens.textScene.hide(label));
      };

      const closeVideo = (label?: string, index?: number) => {
        if (index) {
          const elem = getSelector(`#project-video-scene-${index}`);
          return tl.add(tweens.projectVideo.hideFull(elem, label));
        }
        return tl.add(tweens.textScene.hideFull(label));
      };

      const sliderSceneOut = (elem: THTMLElement, label: string, index?: number) => {
        return tl
          .add(isMobile ? closeVideo(label, index) : tl)
          .add(tweens.sliderScene.hide(elem, label));
      };

      const projectCardSceneOut = (elem: THTMLElement, label: string) => {
        return tl.add(tweens.projectCardScene.hide(elem, label));
      };

      const projectVideoSceneOut = (elem: THTMLElement, label: string) => {
        return tl.add(tweens.projectVideo.hide(elem, label));
      };

      const idleScroll = (duration: number) => {
        return tl.to({}, { duration });
      };

      const closeLast = (closeItem: ICloseItem | null, label: string) => {
        if (!closeItem) {
          return tl;
        }

        const { elem, type, index } = closeItem;

        switch (type) {
          case 'textSceneOut':
            return textSceneOut(label);
          case 'sliderSceneOut':
            return sliderSceneOut(elem, label, index);
          case 'projectCardSceneOut':
            return projectCardSceneOut(elem, label);
          case 'projectVideoSceneOut':
            return projectVideoSceneOut(elem, label);
          default: return tl;
        }
      };

      const generatePlot = () => {
        let closeItem: ICloseItem = {} as ICloseItem;

        repeatedContent.forEach(({ video, slider, description }, index) => {
          if (video && index === 0) {
            tl
              .add(tweens.textScene.init())
              .add(scenes.textSceneTween())
              .add(scenes.newFormatVideoTween(selectors.newFormatVideoWrapperMain));

            closeItem = { type: 'textSceneOut', elem: null };
          }

          if (video && index !== 0) {
            const elem = getSelector(`#project-video-scene-${index}`);
            const label = `projectVideoScene${index}`;
            tweens.projectVideo.init(elem, screenHeight);

            tl
              .add(label)
              .add(closeLast(closeItem, label))
              .add(tweens.projectVideo.show(elem, label))
              .add(idleScroll(commonDuration));

            closeItem = { type: 'projectVideoSceneOut', elem, index };
          }

          if (slider) {
            if (!slider.length) {
              return tl;
            }
            const elem = getSelector(`#slider-scene-${index}`);
            const label = `sliderIn${index}`;
            tweens.sliderScene.init(elem);

            tl
              .add(closeLast(closeItem, label))
              .add(tweens.sliderScene.show(elem, label, slider.length > 0));

            closeItem = { ...closeItem, type: 'sliderSceneOut', elem };
          }

          if (description) {
            const elem = getSelector(`#project-card-${index}`);
            const label = `projectCardIn${index}`;
            tweens.projectCardScene.init(elem);

            tl
              .add(label)
              .add(closeLast(closeItem, label))
              .add(idleScroll(3))
              .add(tweens.projectCardScene.show(elem, label))
              .add(tweens.projectCardScene.imageScale(elem, label))
              .add(idleScroll(2));

            closeItem = { type: 'projectCardSceneOut', elem };
          }
        });

        tl
          .add(tweens.footer.show(closeItem.elem, 'footer'))
          .add(closeItem.index && isMobile ? tweens.footer.hideVideo(getSelector(`#project-video-scene-${closeItem.index}`), 'footer') : tl, 'footer');

        return tl;
      };

      const startPlot = () => {
        tl.to(scrolledFrames, {
          frame: frameScrolledCount - 1,
          snap: 'frame',
          ease: 'none',
          duration: commonDuration,
          onUpdate: render
        });
        tl.add('start')
          .add(scenes.startVideoSceneTween(selectors.startVideoScene, 'start'))
          // .add(idleScroll(3))
          .add(generatePlot());
      };

      gsap.to(initFrames, {
        frame: frameInitCount - 1,
        snap: 'frame',
        ease: 'none',
        duration: 2,
        onUpdate: renderInit,
        onStart: () => {
          smoother?.paused(true);
        },
        onComplete: () => {
          document.body.classList.remove('no-scroll');
          smoother?.paused(false);
          startPlot();
          inited.current = true;
        }
      });
    }, main); // <- Scope!

    return () => {
      ctx.revert();
    }; // <- Cleanup!
  }, [screenWidth, screenHeight, loaded]);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    const resize = debounce(() => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      window.scrollTo(0, 0);
    }, 100);

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
};
