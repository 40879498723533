import { EDataTypes, IDataItem, IRepeatedContent, IVideo } from 'src/shared/types/common';

export const realToVirtualContentAdapter = (data: IDataItem[]): IRepeatedContent[] => {
  const getSlider = (videos: IVideo[]) => {
    return videos.map((video: IVideo) => {
      const { preview, title, player_url, video_number_in_season, video_number } = video;

      return {
        title,
        playerLink: player_url,
        url: preview,
        videoNumberInSeason: video_number_in_season,
        videoNumber: video_number
      };
    });
  };

  return data.map((item) => {
    const { type, data } = item;
    if (type === EDataTypes.Teasers) {
      return {
        video: {
          picDesktop: data.pic_desktop,
          picMobile: data.pic_mobile,
          picTablet: data.pic_tablet,
          videoDesktop: data.video_desktop,
          videoMobile: data.video_mobile,
          videoTablet: data.video_tablet,
          title: data.title,
          subtitle: data.subtitle,
          description: data.project_description
        },
        slider: getSlider(data.videos)
      };
    }

    return {
      description: {
        picDesktop: data.pic_desktop,
        picMobile: data.pic_mobile,
        picTablet: data.pic_tablet,
        title: data.project_title,
        description: data.project_description
      }
    };
  });
};
