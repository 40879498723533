import { commonDuration } from 'src/pages/constants';
import { ISliderSceneTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';
import { THTMLElement } from 'src/shared/types/common';

export const sliderSceneTweens = (tweenProps: ITweenProps): ISliderSceneTweensMethods => {
  const { mq, tl, screenHeight, isMobile } = tweenProps;

  const show = (elem: THTMLElement, label: string, isSlidesExists: boolean) => {
    const q = gsap.utils.selector(elem);
    const slider = q('.slider');
    const logoMiddle = q('.logo-middle');

    if (!isSlidesExists) {
      return tl;
    }

    return tl.to(elem, { y: 0, autoAlpha: 1, duration: commonDuration, height: screenHeight }, label)
      .to(slider, { y: 0, duration: commonDuration }, label)
      .to(logoMiddle, { y: 0, duration: commonDuration }, label)
      .to({}, { duration: isSlidesExists ? 2.5 : 0 })
      .to(logoMiddle, { xPercent: mq([-100, -100, -10]), ease: 'linear', duration: mq([commonDuration, commonDuration, commonDuration]) });
  };

  const hide = (elem: THTMLElement, label: string) => {
    return tl
      .to(elem, { y: -screenHeight, duration: commonDuration }, label);
  };

  const init = (elem: THTMLElement) => {
    const q = gsap.utils.selector(elem);
    const slider = q('.slider');
    const logoMiddle = q('.logo-middle');

    gsap.set(slider, { y: isMobile ? screenHeight : '100vh' });
    gsap.set(logoMiddle, { y: isMobile ? screenHeight : '100vh', xPercent: mq([100, 100, 2]) });
  };

  return {
    init,
    show,
    hide
  };
};
