import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, md, sd } = scales;

export const Slide = styled.div`
  color: #000;
  position: relative;
  height: 100%;
  cursor: pointer;
`;

export const Img = styled.img`
  border-radius: 10px;
  object-fit: cover;
  ${mq({
    maxWidth: [sd(210), null, 'auto', null],
    minHeight: [sd(124), null, md(300), lg(300)],
    maxHeight: [sd(124), null, md(300), lg(300)]
  })}
`;

export const ModalImg = styled.img`
  width: 100%;
  ${mq({
    height: [sd(213), null, lg(800), null]
  })}
  object-fit: cover;
`;

interface IPlayerIFrameProps {
  height: number;
}
export const PlayerIframe = styled.iframe<IPlayerIFrameProps>`
  width: 100%;
  ${mq({
    height: [sd(400), null, lg(800), null]
  })}
  position: relative;
  z-index: 1;
`;

export const Counter = styled.div`
  font-weight: 500;
  position: absolute;
  z-index: 10;
  bottom: 0;
  color: #fff;

  ${mq({
    paddingLeft: [sd(15), null, md(24), lg(24)],
    paddingBottom: [sd(11), null, md(18), lg(18)],
    fontSize: [sd(24), null, md(32), lg(32)]
  })}
`;

export const Play = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;

  ${mq({
    width: [sd(36), null, md(46), lg(54)],
    height: [sd(36), null, md(46), lg(54)]
  })}

  svg {
    fill: #fff;
    position: relative;

    ${mq({
      width: [sd(12), null, md(15), lg(18)],
      height: [sd(14), null, md(18), lg(21)],
      right: [null, null, '-2px', null]
    })}
  }
`;
