import { styled } from '@linaria/react';
import { mq, scales } from 'src/shared/styles/styles.config';

const { sm, sd, md, lg } = scales;

export const TextPlot = styled.div`
  opacity: 0;
  position: absolute;
  z-index: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq({
    paddingLeft: [sd(16), null, md(16), lg(60)],
    paddingRight: [sd(16), null, md(16), lg(60)]
  })}
`;

export const TextWrapper = styled.div``;

export const Text = styled.span`
  font-weight: 400;
  color: #000;

  ${mq({
    fontSize: [sm(28), sd(28), md(32), lg(72)],
    lineHeight: [sm(40), sd(40), md(40), lg(96)]
  })}
`;
