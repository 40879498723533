import { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'shared/assets/svg/arrow.svg';
import { ReactComponent as ArrowHoveredIcon } from 'shared/assets/svg/arrowHovered.svg';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import * as Styled from './slider.styled';
import 'shared/styles/splide.css';

interface IProps<T> {
  slides: T[]
  Slide: React.FunctionComponent<{ slide: T }>
}

export const Slider = <T,>({ slides, Slide }: IProps<T>) => {
  const [arrowHover, setArrowHover] = useState({ prev: false, next: false });

  const onArrowEnter = (kind: 'prev' | 'next') => {
    if (!arrowHover[kind]) {
      setArrowHover((value) => ({ ...value, [kind]: !value[kind] }));
    }
  };
  const onArrowLeave = (kind: 'prev' | 'next') => {
    if (arrowHover[kind]) {
      setArrowHover((value) => ({ ...value, [kind]: !value[kind] }));
    }
  };

  return <Styled.Slider>
    <Splide
      hasTrack={false}
      options={ {
        perPage: 1,
        mediaQuery: 'min',
        gap: '4vw',
        padding: {
          left: '3vw',
          right: '8vw'
        },
        pagination: false,
        breakpoints: {
          600: {
            perPage: 1,
            gap: '1.5vw',
            right: '4vw'
          },
          1024: {
            gap: '1.5vw',
            perPage: 3
          }
        },
        dragMinThreshold: {
          mouse: 0,
          touch: 30
        }
      }}>

      <SplideTrack>
        {slides.map((slide, index) => {
          return <SplideSlide key={index}>
            <Slide slide={slide} />
          </SplideSlide>;
        })}
      </SplideTrack>
      <div className='splide__arrows'>
        <button className='splide__arrow splide__arrow--prev'
          onMouseOver={() => onArrowEnter('prev')}
          onMouseOut={() => onArrowLeave('prev')}
        >
          {arrowHover.prev ? <ArrowHoveredIcon /> : <ArrowIcon />}
        </button>
        <button className='splide__arrow splide__arrow--next'
          onMouseOver={() => onArrowEnter('next')}
          onMouseOut={() => onArrowLeave('next')}>
          {arrowHover.next ? <ArrowHoveredIcon /> : <ArrowIcon />}
        </button>
      </div>

    </Splide>
  </Styled.Slider>;
};
