import { getMq } from 'src/pages/Page/helpers/getMq';
import { getSelectors } from 'src/pages/Page/helpers/getSelectors';
import { ITweens } from 'src/pages/Page/types';
import { footerTweens } from 'src/shared/tweens/footerTweens';
import { headerWrapperTweens } from 'src/shared/tweens/headerWrapperTweens';
import { logoTweens } from 'src/shared/tweens/logoTweens';
import { newFormatVideoTween } from 'src/shared/tweens/newFormatVideoTween';
import { overlayTweens } from 'src/shared/tweens/overlayTweens';
import { projectCardTweens } from 'src/shared/tweens/projectCardTweens';
import { projectVideoTweens } from 'src/shared/tweens/projectVideoTweens';
import { sliderSceneTweens } from 'src/shared/tweens/sliderSceneTweens';
import { startVideoSceneTweens } from 'src/shared/tweens/startVideoSceneTweens';
import { textSceneTweens } from 'src/shared/tweens/textSceneTweens';
import { ITweenProps } from 'src/shared/tweens/types';
import { videoIframeTweens } from 'src/shared/tweens/videoIframeTweens';
import { MediaTypes } from 'src/shared/types/layout';

export const Tweens = (self: gsap.Context, timeline: gsap.core.Timeline, media: MediaTypes): ITweens | undefined => {
  const mq = getMq(media);

  const isMobile = media === MediaTypes.Mobile;

  const { selectors } = getSelectors(self) || {};

  if (!selectors) {
    return;
  }

  const screenHeight = window.innerHeight - selectors.header[0].clientHeight;

  const tweenProps: ITweenProps = { mq, selectors, tl: timeline, isMobile, screenHeight };

  const startVideoScene = startVideoSceneTweens(tweenProps);
  const headerWrapper = headerWrapperTweens(tweenProps);
  const logo = logoTweens(tweenProps);
  const overlay = overlayTweens(tweenProps);
  const videoIframe = videoIframeTweens(tweenProps);
  const textScene = textSceneTweens(tweenProps);
  const newFormatVideo = newFormatVideoTween(tweenProps);
  const sliderScene = sliderSceneTweens(tweenProps);
  const projectCardScene = projectCardTweens(tweenProps);
  const projectVideo = projectVideoTweens(tweenProps);
  const footer = footerTweens(tweenProps);

  return {
    startVideoScene,
    headerWrapper,
    logo,
    overlay,
    videoIframe,
    textScene,
    newFormatVideo,
    sliderScene,
    projectCardScene,
    projectVideo,
    footer
  };
};
