import { isMobile } from 'src/shared/helpers/isMobile';

import { detect } from 'detect-browser';

export const browserInfo = detect();

export const commonDuration = (() => {
  const desktopDuration = 10;
  const mobileDuration = 10;

  if (isMobile()) {
    return mobileDuration;
  }

  return desktopDuration;
})();

export const ghostSafariPixels = browserInfo?.os === 'iOS' ? 2 : 0;
export const mainText = 'Актёры сериала «Молодёжка» встретятся спустя десять лет после премьеры, чтобы вспомнить свои первые шаги на льду, яркие сцены, и то,\n как проект изменил их жизни';

export const links = {
  tg: 'https://t.me/channel_ctc',
  vk: 'https://vk.com/ctc',
  ok: 'https://ok.ru/ctc',
  ctc: 'https://ctc.ru'
};
