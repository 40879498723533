import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, sd, md } = scales;

export const Socials = styled.div`
  display: flex;

  a {
    ${mq({
      marginRight: [sd(16), null, md(35), lg(35)]
    })}
    :last-child {
      margin-right: 0;
    }
    &:hover {
      svg {
        color: rgb(31, 31, 31);
      }
    }
  }

  svg {
    ${mq({
      width: [sd(32), null, md(40), lg(40)],
      height: [sd(32), null, md(40), lg(40)]
    })}
    color: #000;
  }
`;

export const Logo = styled.div`
  svg {
    ${mq({
      width: [sd(68), null, md(114), lg(114)],
      height: [sd(24), null, md(80), lg(80)]
    })}
  }
`;

export const Header = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;

  ${mq({
    height: [sd(50), null, md(80), lg(80)]
  })}
`;
