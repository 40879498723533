import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, md, sd, sm } = scales;

export const ProjectCard = styled.div`
  z-index: 5;
  opacity: 0;
  position: absolute;
  background-color: rgb(255, 251, 242);
  width: 100%;
  height: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq({
    flexDirection: ['column', null, 'row', null]
  })}
`;

export const ProjectImage = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;

  ${mq({
    width: [sm(175), sd(175), md(500 / 2), lg(500)],
    marginBottom: [sm(48), sd(48), 0, null],
    marginRight: [0, null, md(80 / 2), lg(80)]
  })}

  @media ((min-width: 450px) and (max-width: 599px)) {
    margin-bottom: ${sd(48 / 2)};
  }

  img {
    ${mq({
      width: [sm(175), sd(175), md(500 / 2), lg(500)],
      height: [sm(250), sd(250), md(700 / 2), lg(700)],
      borderRadius: [sm(10), sd(10), md(32 / 2), lg(32)]
    })}
  }
`;

export const ProjectCardInfo = styled.div`
  ${mq({
    width: ['100%', null, md(900 / 2), lg(900)]
  })}
`;

export const ProjectCardTitle = styled.div`
  color: rgb(255, 115, 79);
  font-weight: 400;
  opacity: 0;

  ${mq({
    lineHeight: [sm(25), sd(25), md(42), lg(85)],
    marginLeft: [0, 0, lg(-2), null],
    marginBottom: [sm(16), sd(16), md(40 / 2), lg(40)],
    textAlign: ['center', null, 'left', null],
    fontSize: [sm(23), sd(23), md(72 / 2), lg(72)]
  })}
`;

export const ProjectCardDescription = styled.div`
  color: #000;
  font-weight: 400;
  opacity: 0;
  line-height: normal;
  white-space: pre-line;

  ${mq({
    fontSize: [sm(10), sd(10), md(17), lg(34)],
    textAlign: ['center', null, 'left', null]
  })}

  @media ((min-width: 500px) and (max-width: 599px)) {
    display: none;
  }
`;
