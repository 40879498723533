import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  rootElement: string
  children: React.ReactNode
}

export const Portal = ({ children, rootElement }: IProps) => {
  const [container] = useState(() => {
    const el = document.createElement('div');
    el.className = rootElement;
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};
