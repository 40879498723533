import { ITweens } from 'src/pages/Page/types';
import { commonDuration } from 'src/pages/constants';

interface IProps {
  tweens?: ITweens
  timeline: gsap.core.Timeline
}
export const textSceneTween = ({ tweens, timeline: tl }: IProps) => {
  if (!tweens) {
    return tl;
  }

  return tl.add('coolText')
    // .add(tweens.startVideoScene.startVideoSceneOut())
    .add(tweens.textScene.show('coolText'))
    .add(tweens.textScene.coolTextAppearance('coolText'))
    .to({}, { duration: commonDuration })
    .add(tweens.newFormatVideo.miniVideoIn('coolText'));
};
