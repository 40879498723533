import { IRepeatedContentVideo } from 'src/shared/types/common';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getMq } from 'src/pages/Page/helpers/getMq';
import { NewFormatVideo } from '../NewFormatVideo';
import * as Styled from './projectVideoPlot.styled';

interface IProps {
  video: IRepeatedContentVideo
  index: number
}
export const ProjectVideoPlot = ({ video, index }: IProps) => {
  const media = useSelector((state: RootState) => state.layout.media);
  const mq = getMq(media);

  const { videoDesktop, title, subtitle, description, picDesktop, picMobile, picTablet } = video;

  return <Styled.ProjectVideoPlot id={`project-video-scene-${index}`}>
    <NewFormatVideo
      previewLink={mq([picMobile, picTablet, picDesktop])}
      link={videoDesktop}
      title={title}
      subtitle={subtitle}
      description={description}
    />
  </Styled.ProjectVideoPlot>;
};
