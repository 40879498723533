import { useEffect, useState, DependencyList } from 'react';

export const useUpdateEffect = (callback: () => void, inputs: DependencyList) => {
  if (!inputs.length) {
    throw new Error('You should provide `inputs` for `useUpdateEffect` hook, bro!');
  }

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }

    callback();
  }, inputs);
};
