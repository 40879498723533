import React from 'react';
import ReactDOM from 'react-dom/client';
import './shared/styles/normalize.css';
import './shared/styles/index.css';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { App } from './app/components';
import { reportWebVitals } from './shared/helpers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
