export const truncateText = (text: string, numWords: number) => {
  const words = text.split(' ');

  if (words.length > numWords) {
    words.length = numWords;
    words[words.length - 1] += '...';
  }

  return words.join(' ');
};
