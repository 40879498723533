import { commonDuration } from 'src/pages/constants';
import { scales, vw } from 'shared/styles/styles.config';
import { IProjectVideoTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';
import { THTMLElement } from 'src/shared/types/common';
import { TMq } from 'src/pages/Page/types';
const { lg, md, sd } = scales;

const onNewFormatVideoUpdate = function (mq: TMq, elem: THTMLElement) {
  const q = gsap.utils.selector(elem);
  const newFormatTopText = q('.new-format-top-text');
  const newFormatBottomText = q('.new-format-bottom-text');
  let animation: gsap.core.Tween | null = null;
  let newFormatTextAnimation: gsap.core.Timeline | null = null;
  const newForamtTextTimeline = gsap.timeline();

  const roundingCorners = (self: gsap.core.Tween) => {
    if (self.ratio > 0.5 && !animation) {
      animation = gsap.to(elem, {
        borderRadius: 0, marginLeft: 0
      });
    } else {
      if (self.ratio < 0.5 && animation) {
        animation.revert();
        animation = null;
      }
    }
  };

  const newFormatTextAppearance = (self: gsap.core.Tween) => {
    if (self.ratio > 0.97) {
      if (!newFormatTextAnimation) {
        newFormatTextAnimation = newForamtTextTimeline
          .to(newFormatTopText, { top: mq([sd(18), md(20), lg(20)]), opacity: 1 })
          .to(newFormatBottomText, { bottom: mq([sd(24), md(100), lg(100)]), opacity: 1 });
      } else {
        newFormatTextAnimation.play();
      }
    } else if (self.ratio < 0.97 && newFormatTextAnimation) {
      const velocity = self.parent?.scrollTrigger?.getVelocity();
      const timeScaleValue = velocity && Math.abs(velocity) > 500 ? 20 : 2;
      newFormatTextAnimation.timeScale(timeScaleValue).reverse();
    }
  };
  return function () {
    roundingCorners(this);
    newFormatTextAppearance(this);
  };
};

export const projectVideoTweens = (tweenProps: ITweenProps): IProjectVideoTweensMethods => {
  const { mq, tl, isMobile, screenHeight } = tweenProps;

  const show = (elem: THTMLElement, label: string) => {
    const q = gsap.utils.selector(elem);
    const newFormatVideoWrapper = q('.new-format-video-wrapper');

    return tl.to(elem, {
      y: 0,
      opacity: 1,
      duration: commonDuration,
      onUpdate: onNewFormatVideoUpdate(mq, newFormatVideoWrapper)
    }, label);
  };

  const hideFull = (elem: THTMLElement, label?: string) => {
    return tl.to(elem, {
      y: isMobile ? -screenHeight : '-100vh',
      duration: commonDuration
    }, label);
  };

  const hide = (elem: THTMLElement, label: string) => {
    if (!isMobile) {
      return hideFull(elem, label);
    }

    return tl.to(elem, {
      duration: commonDuration,
      height: mq([vw(327), 0, 0])
    }, label);
  };

  const init = (elem: THTMLElement, screenHeight: number) => {
    gsap.set(elem, { height: screenHeight, y: screenHeight });
  };

  return {
    init,
    show,
    hide,
    hideFull
  };
};
