import { Slider } from 'src/shared/components/Slider';
import { ReactComponent as LogoMiddle } from 'shared/assets/svg/logoMiddle.svg';
import { Slide } from 'src/pages/Page/components/Slide';
import { ISlide } from 'shared/types/common';
import { Media } from 'src/shared/hocs/Media';
import { MediaTypes } from 'src/shared/types/layout';
import * as Styled from './sliderScene.styled';
import { vw } from 'src/shared/styles/styles.config';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getMq } from 'src/pages/Page/helpers/getMq';

interface IProps {
  id: string
  slides: ISlide[]
}
export const SliderScene = ({ id, slides }: IProps) => {
  const isSliderExists = slides.length > 0;

  const media = useSelector((state: RootState) => state.layout.media);
  const mq = getMq(media);

  if (!isSliderExists) {
    return null;
  }

  return <Styled.SliderScene id={id} paddingTop={mq([vw(327), 0, 0])} >
    <Styled.Container>
      <Styled.Slider className='slider'>
        <Slider<ISlide>
          slides={slides}
          Slide={Slide}
        />
      </Styled.Slider>
      <Styled.LogoMiddle className={`logo-middle${isSliderExists ? '' : ' no-slider'}`}>
        <Styled.Marquee className='marquee' >
          <Media medias={[MediaTypes.Desktop]}>
            <LogoMiddle /><LogoMiddle /><LogoMiddle />
          </Media>
          <Media medias={[MediaTypes.Tablet, MediaTypes.Mobile]}>
            <LogoMiddle />
          </Media>
        </Styled.Marquee>
      </Styled.LogoMiddle>
    </Styled.Container>
  </Styled.SliderScene>;
};
