import { MediaTypes } from 'src/shared/types/layout';

export const getMq = (media: MediaTypes) => {
  return <T>(values: Array<T>) => {
    switch (media) {
      case MediaTypes.Desktop:
        return values[2];
      case MediaTypes.Tablet:
        return values[1];
      default:
        return values[0];
    }
  };
};
