import { styled } from '@linaria/react';
import { mq, scales } from 'shared/styles/styles.config';

const { lg, sm, sd, md } = scales;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke: rgba(255, 227, 214, 1); /* Color Stop 1 */
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
      stroke: rgba(255, 189, 58, 1); /* Color Stop 2 */
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
      stroke: rgba(207, 13, 36, 1); /* Color Stop 3 */
    }
  }
`;

export const CenterBody = styled.div``;

export const LoaderCircle = styled.div`
  position: relative;
  margin: 0 auto;
  width: 70px;
  ${mq({
    width: [sm(100), sd(100), md(100), lg(100)]
  })}

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const LoaderText = styled.div`
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  margin-top: 3px;

  ${mq({
    fontSize: [sm(28), sd(28), md(36), lg(36)]
  })}
`;
