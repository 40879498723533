import { commonDuration } from 'src/pages/constants';
import { ITextSceneTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { vw } from 'shared/styles/styles.config';

export const textSceneTweens = (tweenProps: ITweenProps): ITextSceneTweensMethods => {
  const { selectors, tl, screenHeight, isMobile, mq } = tweenProps;
  const { textScene, newFormatVideoWrapperMain, header, text } = selectors;

  const coolTextAppearance = (label: string) => {
    const childSplit = new SplitText(text, {
      type: 'lines',
      tag: 'span',
      linesClass: 'split-child'
    });

    const parentSplit = new SplitText(text, {
      tag: 'span',
      type: 'lines',
      linesClass: 'split-parent'
    });

    return tl.from(childSplit.lines, {
      // delay: isMobile ? 6 : 6,
      duration: 4,
      yPercent: 100,
      ease: 'power4',
      stagger: 0.1
    }, label);
  };

  const show = (label: string) => {
    return tl.to(textScene, {
      opacity: 1
    }, label);
  };

  const hideFull = (label?: string) => {
    return tl.to(textScene, {
      ease: 'power1.out',
      duration: commonDuration,
      y: () => isMobile ? -screenHeight : -(newFormatVideoWrapperMain[0].clientHeight - header[0].clientHeight) - header[0].clientHeight,
      display: 'none'
    }, label);
  };

  const hide = (label: string) => {
    if (!isMobile) {
      return hideFull(label);
    }

    return tl
      .to(text, { autoAlpha: 0 })
      .to(newFormatVideoWrapperMain, {
        duration: commonDuration,
        height: mq([vw(327), 0, 0])
      }, label);
  };

  const init = () => {
    return tl.set(textScene, {
      y: 0,
      width: window.innerWidth,
      height: () => screenHeight
    });
  };

  return {
    hideFull,
    coolTextAppearance,
    show,
    hide,
    init
  };
};
