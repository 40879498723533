import { styled } from '@linaria/react';
import { mq, scales } from 'src/shared/styles/styles.config';

const { sd, lg, md } = scales;

export const Slider = styled.div`
  & .splide__arrow--prev[disabled],
  .splide__arrow--next[disabled] {
    display: none;
  }

  & .splide__arrow--prev,
  .splide__arrow--next {
    background: transparent;
    ${mq({
      display: ['none', null, 'block', 'block']
    })}
  }

  & .splide__slide {
    border-radius: 10px;

    ${mq({
      maxWidth: [sd(210), null, 'auto', null]
    })}
  }

  & .splide__arrow {
    width: auto;
    height: auto;
  }

  & .splide__arrow svg {
    width: 100%;
    height: 100%;

    ${mq({
      width: ['100%', null, md(90), lg(90)],
      height: ['100%', null, md(90), lg(90)]
    })}
  }

  & .splide_arrow {
    -webkit-tap-highlight-color: transparent;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .splide__track {
    padding: 10px;
  }

  .splide__slide {
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    transition-property: transform;
  }

  .splide__slide:hover {
    ${mq({
      transform: [null, null, null, 'scale(1.05)']
    })}
  }
`;
