import { useEffect, useState } from 'react';
import { IResponse } from 'src/shared/types/common';

export const useGetContent = (url: string) => {
  const [data, setData] = useState<IResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data: IResponse) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [url]);

  return { data: data ? data.data : null, loading, error };
};
