import { getSelectors } from 'src/pages/Page/helpers/getSelectors';
import { Tweens } from 'src/pages/Page/tweens';
import { IScenes } from 'src/pages/Page/types';
import { newFormatVideoTween } from 'src/shared/scenes/newFormatVideoTween';
import { startVideoSceneTween } from 'src/shared/scenes/startVideoSceneTween';
import { textSceneTween } from 'src/shared/scenes/textSceneTween';
import { THTMLElement } from 'src/shared/types/common';
import { MediaTypes } from 'src/shared/types/layout';

export const Scenes = (self: gsap.Context, timeline: gsap.core.Timeline, media: MediaTypes): IScenes => {
  const { selectors } = getSelectors(self) || {};

  if (!selectors) {
    return {} as IScenes;
  }

  const tweens = Tweens(self, timeline, media);

  const startVideoScene = (elem: THTMLElement, label: string) => {
    return startVideoSceneTween({ elem, label, media, tweens, timeline });
  };

  const textScene = () => {
    return textSceneTween({ tweens, timeline });
  };

  const newFormatVideo = (elem: THTMLElement) => {
    return newFormatVideoTween({ elem, tweens, timeline });
  };

  return {
    startVideoSceneTween: startVideoScene,
    textSceneTween: textScene,
    newFormatVideoTween: newFormatVideo
  };
};
