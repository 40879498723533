import MultiClamp from 'react-multi-clamp';

interface IProps {
  text: string
  lines: number
}

export const LimitedText = ({ text, lines }: IProps) => {
  return <MultiClamp ellipsis='...' clamp={lines}>{text}</MultiClamp>;
};
