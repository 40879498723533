import { styled } from '@linaria/react';
import { mq, scales } from 'src/shared/styles/styles.config';

const { lg, sd, md } = scales;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  ${mq({
    position: ['inhert', null, 'relative', null],
    width: ['100%', null, '70%', null]
  })}
`;

export const Close = styled.span`
  position: absolute;
  cursor: pointer;

  ${mq({
    top: [sd(50), null, 0, null],
    right: [sd(10), null, md(-40), lg(-40)]
  })}
`;

export const CloseIcon = styled.div`
  color: #fff;
  position: relative;
`;
