import { commonDuration } from 'src/pages/constants';
import { scales } from 'shared/styles/styles.config';
import { INewFormatVideoTweenMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';
import { THTMLElement } from 'src/shared/types/common';
const { lg, md, sd } = scales;

export const newFormatVideoTween = (tweenProps: ITweenProps): INewFormatVideoTweenMethods => {
  const { mq, selectors, tl, screenHeight, isMobile } = tweenProps;
  const { newFormatVideoWrapperMain } = selectors;

  const videoIn = (elem: THTMLElement, label: string) => {
    return tl.to(elem, {
      width: '100%',
      height: screenHeight,
      right: 0,
      top: 0,
      ease: 'power1.out',
      force3D: true,
      duration: commonDuration
    }, label);
  };

  const collapseBorders = (elem: THTMLElement, label: string) => {
    return tl.to(elem, {
      borderRadius: 0, marginLeft: 0
    }, label);
  };

  const videoTextShow = (elem: THTMLElement, label: string) => {
    const q = gsap.utils.selector(elem);
    const newFormatTopText = q('.new-format-top-text');
    const newFormatBottomText = q('.new-format-bottom-text');

    return tl
      .to(newFormatTopText, { duration: 3, top: mq([sd(18), md(20), lg(20)]), opacity: 1 }, label)
      .to(newFormatBottomText, { duration: 3, bottom: mq([sd(24), md(100), lg(100)]), opacity: 1 }, label)
      .to({}, { duration: commonDuration });
  };

  const miniVideoIn = (label: string) => {
    return tl.to(newFormatVideoWrapperMain, { opacity: 1, delay: isMobile ? 6 : 7, duration: 2 }, label);
  };

  return {
    miniVideoIn,
    videoIn,
    collapseBorders,
    videoTextShow
  };
};
