import { Page } from 'pages/Page';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { useDetectOs } from 'src/shared/hooks/useDetectOs';
import { useMedia } from 'src/shared/hooks/useMedia';

export const App = () => {
  useMedia();
  useDetectOs();

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Page />
    }
  ]);

  return <RouterProvider router={router} />;
};
