import React from 'react';
import { ReactComponent as Close } from 'shared/assets/svg/close.svg';
import * as Styled from './modal.styled';

interface IProps {
  children: React.ReactNode
  onClose: () => void
}
export const Modal = ({ children, onClose }: IProps) => {
  const onModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <Styled.Modal onClick={onModalClick}>
      <Styled.ModalContent>
        <Styled.Close onClick={onClose}>
          <Styled.CloseIcon ><Close /></Styled.CloseIcon>
        </Styled.Close>
        {children}
      </Styled.ModalContent>
    </Styled.Modal>
  );
};
