import { commonDuration } from 'src/pages/constants';
import { IFooterTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { THTMLElement } from 'src/shared/types/common';
import { gsap } from 'gsap';

export const footerTweens = (tweenProps: ITweenProps): IFooterTweensMethods => {
  const { selectors: { footer }, tl, screenHeight, isMobile } = tweenProps;
  const q = gsap.utils.selector(footer[0]);
  const footerContent = q('#footer-content');

  gsap.set(footer, { height: screenHeight });
  gsap.set(footerContent, { bottom: -footerContent[0].clientHeight });

  const show = (closeItemElem: THTMLElement, label: string) => {
    return tl
      .add(label)
      .to(footer, { y: 0, visibility: 'visible', duration: commonDuration }, label)
      .to(footerContent, { bottom: isMobile ? -1 : 0, duration: commonDuration - 1 }, label)
      .to(closeItemElem, {
        duration: commonDuration,
        y: -footerContent[0].clientHeight
      }, label);
  };

  const hideVideo = (closeItemElem: THTMLElement, label: string) => {
    return tl.to(closeItemElem, {
      duration: commonDuration,
      y: -footerContent[0].clientHeight
    }, label);
  };

  return {
    hideVideo,
    show
  };
};
