import { IRepeatedContentDescription } from 'src/shared/types/common';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getMq } from 'src/pages/Page/helpers/getMq';
import { ProjectCardScene } from 'src/pages/Page/components/ProjectCardScene';

interface IProps {
  description: IRepeatedContentDescription
  index: number
}

export const ProjectCardPlot = ({ description, index }: IProps) => {
  const media = useSelector((state: RootState) => state.layout.media);
  const mq = getMq(media);

  const { picDesktop, picTablet, picMobile, title } = description;

  return <ProjectCardScene
    id={`project-card-${index}`}
    imgLink={mq([picMobile, picTablet, picDesktop])}
    title={title}
    description={description.description}
  />;
};
