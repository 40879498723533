import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Breakpoints, MediaTypes } from 'src/shared/types/layout';

export interface ILayoutsState {
  media: MediaTypes
  screenWidth: number
}

const initialState: ILayoutsState = {
  media: MediaTypes.Desktop,
  screenWidth: Breakpoints.Desktop
};

export const layoutsSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMedia: (state, action: PayloadAction<MediaTypes>) => {
      state.media = action.payload;
    }
  }
});

export const { setMedia } = layoutsSlice.actions;

export default layoutsSlice.reducer;
