import { commonDuration } from 'src/pages/constants';
import { scales } from 'shared/styles/styles.config';
import { IHeaderWrapperTweensMethods, ITweenProps } from 'src/shared/tweens/types';
const { lg, md, sd } = scales;

export const headerWrapperTweens = (tweenProps: ITweenProps): IHeaderWrapperTweensMethods => {
  const { mq, selectors: { headerWrapper }, tl } = tweenProps;

  const reduceHeight = (label: string) => {
    return tl.to(headerWrapper, {
      duration: commonDuration,
      height: () => mq([sd(50), md(80), lg(80)])
    }, label);
  };

  return {
    reduceHeight
  };
};
