import { commonDuration, ghostSafariPixels } from 'src/pages/constants';
import { IStartVideoSceneTweenMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';

export const startVideoSceneTweens = (tweenProps: ITweenProps): IStartVideoSceneTweenMethods => {
  const { mq, selectors: { startVideoScene, isMobile }, tl } = tweenProps;

  const startVideoSceneInMobile = (label: string) => {
    return tl.to(startVideoScene, {
      y: 0,
      width: '100%',
      duration: commonDuration
    }, label)
      .add(isMobile ? tl.to({}, { duration: commonDuration }) : tl);
  };

  const startVideoSceneIn = (label: string) => {
    return tl.to(startVideoScene, {
      y: 0,
      width: '100%',
      opacity: 1,
      duration: commonDuration
    }, label);
  };

  const startVideoSceneOut = (label?: string) => {
    return tl.to(startVideoScene, {
      y: '-101%',
      duration: commonDuration,
      ease: 'linear'
    }, label);
  };

  const init = (screenHeight: number) => {
    gsap.set(startVideoScene, {
      visibility: 'visible',
      y: '100%',
      xPercent: -50,
      left: '50%',
      width: mq(['100%', '90%', '90%']),
      opacity: mq([1, 0, 0]),
      height: mq([screenHeight + ghostSafariPixels, screenHeight, screenHeight]),
      force3D: true
    });
  };

  return {
    init,
    startVideoSceneInMobile,
    startVideoSceneIn,
    startVideoSceneOut
  };
};
