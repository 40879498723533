import { Portal } from 'src/shared/hocs';
import { Modal } from 'src/shared/components/Modal';
import { useContext, useEffect, useState } from 'react';
import { ISlide } from 'src/shared/types/common';
import { useUpdateEffect } from 'src/shared/hooks';
import { ReactComponent as PlayIcon } from 'shared/assets/svg/play.svg';
import { PageContext } from '../../context';
import * as Styled from './slide.styled';

interface ISlideProps {
  slide: ISlide
}

export const Slide = ({ slide }: ISlideProps) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { smoother } = useContext(PageContext);

  const onClose = () => {
    setIsModalOpen(false);
  };
  const onSlideClick = () => {
    setIsLoading(true);
    setIsModalOpen(true);
  };

  const onLoad = () => {
    setIsLoading(false);
  };

  useUpdateEffect(() => {
    smoother?.paused(isModalOpen);
  }, [isModalOpen]);

  const loadImageAsBase64 = () => {
    const imageUrl = slide.url;

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setImageSrc(base64String as string);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error('Error loading the image:', error);
      });
  };

  useEffect(() => {
    loadImageAsBase64();
  }, []);

  return <Styled.Slide onClick={onSlideClick}>
    <Styled.Play><PlayIcon /></Styled.Play>
    <Styled.Img src={imageSrc} />
    {/* <Styled.Counter>{slide.videoNumber}</Styled.Counter> */}
    {isModalOpen && <Portal rootElement='modal'>
      <Modal onClose={onClose}>
        {slide.playerLink
          ? <Styled.PlayerIframe height={window.innerHeight} src={slide.playerLink} allow='encrypted-media;autoplay;fullscreen;clipboard-write' />
          : <Styled.ModalImg src={imageSrc} onLoad={onLoad} />}
      </Modal>
    </Portal>
    }
  </Styled.Slide>;
};
