import { commonDuration } from 'src/pages/constants';
import { IProjectCardTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';
import { THTMLElement } from 'src/shared/types/common';
import { scales } from 'shared/styles/styles.config';
const { lg, md, sd } = scales;

const onProjectCardUpdate = function (elem: THTMLElement, isMobile: boolean) {
  let projectCardAnimation: any = null;
  const projectCardTimeline = gsap.timeline();

  const q = gsap.utils.selector(elem);
  const projectCardTitle = q('.project-card-title');
  const projectCardDescription = q('.project-card-description');

  const triggerValue = !isMobile ? 0.8 : 0.95;
  const textAppearance = (self: any) => {
    if (self.ratio > triggerValue && !projectCardAnimation) {
      projectCardAnimation = projectCardTimeline
        .to(projectCardTitle, { opacity: 1 })
        .to(projectCardDescription, { opacity: 1, delay: 0.2 });
    }
  };
  return function () {
    textAppearance(this);
  };
};

export const projectCardTweens = (tweenProps: ITweenProps): IProjectCardTweensMethods => {
  const { tl, isMobile, screenHeight } = tweenProps;

  const show = (elem: THTMLElement, label: string) => {
    return tl.to(elem, {
      opacity: 1,
      y: 0,
      height: screenHeight,
      duration: commonDuration,
      onUpdate: onProjectCardUpdate(elem, isMobile)
    }, label)
      .add(!isMobile ? tl.to(elem, { duration: 5 }) : tl);
  };

  const imageScale = (elem: THTMLElement, label: string) => {
    const q = gsap.utils.selector(elem);
    const projectCardImage = q('.project-image img');
    return tl.to(projectCardImage, { scale: 1, duration: commonDuration }, label);
  };

  const hide = (elem: THTMLElement, label: string) => {
    return tl.to(elem, { y: -screenHeight, duration: commonDuration }, label);
  };

  const init = (elem: THTMLElement) => {
    const q = gsap.utils.selector(elem);
    const projectCardImage = q('.project-image img');
    gsap.set(elem, { y: isMobile ? screenHeight : '100vh' });
    gsap.set(projectCardImage, { scale: 0.8 });
  };

  return {
    init,
    imageScale,
    show,
    hide
  };
};
