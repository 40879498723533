import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { Breakpoints, MediaTypes } from 'shared/types/layout';
import { setMedia } from 'src/pages/Page/store/layouts.slice';

const getMedia = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth < Breakpoints.Tablet) {
    return MediaTypes.Mobile;
  }

  if (windowWidth < Breakpoints.Desktop) {
    return MediaTypes.Tablet;
  }

  return MediaTypes.Desktop;
};

export const useMedia = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const resize = () => {
      dispatch(setMedia(getMedia()));
    };

    resize();

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
};
