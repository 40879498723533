import { LimitedText } from 'src/shared/hocs/LimitedText';
import * as Styled from './projectCardScene.styled';

interface IProps {
  id: string
  imgLink: string
  title: string
  description: string
}
export const ProjectCardScene = ({ id, imgLink, title, description }: IProps) => {
  const descriptionLines = window.innerHeight < 700 ? 3 : 6;

  return <Styled.ProjectCard id={id} >
    <Styled.ProjectImage className='project-image'>
      <img src={imgLink} />
    </Styled.ProjectImage>
    <Styled.ProjectCardInfo className='project-card-info'>
      <Styled.ProjectCardTitle className='project-card-title'>
        {/* <LimitedText text={title} lines={3} /> */}
        {title}
      </Styled.ProjectCardTitle>
      <Styled.ProjectCardDescription className='project-card-description' dangerouslySetInnerHTML={{ __html: description }}>
        {/* <LimitedText text={description} lines={descriptionLines} /> */}
      </Styled.ProjectCardDescription>
    </Styled.ProjectCardInfo>
  </Styled.ProjectCard>;
};
