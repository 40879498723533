import { commonDuration } from 'src/pages/constants';
import { ITweenProps, IVideoIframeTweensMethods } from 'src/shared/tweens/types';
import { THTMLElement } from 'src/shared/types/common';

export const videoIframeTweens = (tweenProps: ITweenProps): IVideoIframeTweensMethods => {
  const { tl, isMobile } = tweenProps;

  const collapseBorders = (elem: THTMLElement, label: string) => {
    if (isMobile) {
      return tl;
    }
    return tl.to(elem, { borderRadius: 0, duration: commonDuration, delay: 2 }, label);
  };

  return {
    collapseBorders
  };
};
