import { commonDuration } from 'src/pages/constants';
import { scales } from 'shared/styles/styles.config';
import { ILogoTweensMethods, ITweenProps } from 'src/shared/tweens/types';
import { gsap } from 'gsap';

const { lg, md, sd } = scales;

export const logoTweens = (tweenProps: ITweenProps): ILogoTweensMethods => {
  const { mq, selectors: { mainLogo }, tl, screenHeight } = tweenProps;

  const hideLogoMobile = () => {
    return tl.to(mainLogo, {
      autoAlpha: 0
    });
  };

  const schrinkLogo = (label: string) => {
    return tl.to(mainLogo, {
      duration: commonDuration,
      x: 0,
      opacity: mq([0, 1, 1]),
      top: mq([sd(40), md(40), lg(40)]),
      height: 0,
      scale: 0.2,
      zIndex: 11,
      ease: 'power1.out'
    }, label);
  };

  const init = () => {
    gsap.set(mainLogo, { width: '100%', height: window.innerHeight }); //top: getVhOrSvh(50)
  };

  return {
    init,
    hideLogoMobile,
    schrinkLogo
  };
};
